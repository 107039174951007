import React from "react"
import { Link } from "gatsby"
import Logo from "../images/hommerfitness-logo.png"
import WhatsappIcon from "../images/whatsapp-icon.png"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="global-inner">
          <div className="row">
            <div className="col s12 m12 l3 footer-contact footer-block">
              <h5>Contato</h5>
              <div className="unit-block">
                <div className="unit-number">Endereço</div>

                <div className="unit-information">
                  <p>Telefone: (11) 2011-8699</p>
                  <p>Endereço:</p>
                  <p>Rua André de Almeida, 711</p>
                  <p>Jardim Nove de Julho</p>
                  <p>São Paulo - SP</p>
                  <p className="whatsapp-click">
                    <a
                      href="https://wa.me/5511958546063"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="whatsapp-logo">
                        <img src={WhatsappIcon} alt="Whatsapp" />
                      </span>
                      (11) 95854-6063
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="col s12 m12 l3 footer-links footer-block">
              <h5>Nossos Links</h5>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/sobre">A Hommer Fitness</Link>
                </li>
                <li>
                  <Link to="/modalidades">Modalidades</Link>
                </li>
                <li>
                  <Link to="/galeria">Galeria</Link>
                </li>
                <li>
                  <Link to="/promocoes">Promoções</Link>
                </li>
                <li>
                  <Link to="/eventos">Eventos</Link>
                </li>
                <li>
                  <Link to="/contato">Contato</Link>
                </li>
              </ul>
            </div>

            <div className="col s12 m12 l3 footer-social-medias footer-block">
              <h5>Redes Sociais</h5>
              <ul>
                <li>
                  <a
                    href="https://fb.com/hommerfitness/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/hommerfitness_/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/5511958546063"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whatsapp: (11) 95854-6063
                  </a>
                </li>
              </ul>
            </div>

            <div className="col s12 m12 l3 footer-about footer-block">
              <h5>Sobre Nós</h5>

              <div className="about-text">
                <div className="logo-block">
                  <img src={Logo} alt="HommerFitness Logo" />
                </div>

                <p>
                  Nós da Hommer trabalhamos com o propósito de trazer ao nosso
                  público o melhor do mundo fitness, aliado a ambientes
                  agradáveis e ao serviço de profissionais altamente
                  qualificados. Oferecemos diversas modalidades, para os mais
                  variados gostos e níveis de aptidão física. Se você ainda não
                  conhece a Hommer Fitness, venha agora conhecer.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="copyrights">
          <p className="center">HommerFitness - {new Date().getFullYear()}</p>
        </div>
      </footer>
    )
  }
}

export default Footer
