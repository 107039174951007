import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="Promoções">
    <div className="row">
      <div className="promotions-wrapper">
        <h1 className="center background-title">Promoções!</h1>

        <div className="global-inner">
          <div className="promotion-block col s12">
            <h5>Plano Anual</h5>
            <p>12x R$ 109,00 sem juros - Musculação +Fitness</p>
            <p>12x R$ 129,00 sem juros - Artes Marciais +Musculação +Fitness</p>

            <div className="line-divider"></div>
          </div>

          <div className="promotion-block col s12">
            <h5>Mensal Hommer Fitness</h5>
            <p>R$ 145,00 - Musculação +Fitness</p>
            <p>R$ 165,00 - Artes Marciais +Musculação +Fitness</p>

            <div className="line-divider"></div>
          </div>

          <div className="promotion-block col s12">
            <h5>Trimestral Hommer Fitness</h5>
            <p>3x R$ 135,00 sem juros - Musculação +Fitness</p>
            <p>3x R$ 155,00 sem juros - Artes Marciais +Musculação +Fitness</p>

            <div className="line-divider"></div>
          </div>

          <div className="promotion-block col s12">
            <h5>Semestral Hommer Fitness</h5>
            <p>6x R$ 125,00 sem juros - Musculação +Fitness</p>
            <p>6x R$ 145,00 sem juros - Artes Marciais +Musculação +Fitness</p>

            <div className="line-divider"></div>
          </div>

          <div className="promotion-block col s12">
            <h5>Taxa de Matrícula</h5>
            <p>R$ 30,00 - Apenas para quem ainda não possui cadastro</p>

            <div className="line-divider"></div>
          </div>

          <div className="promotion-block col s12">
            <h5>Free Pass</h5>
            <p>
              Traga um amigo para treinar de graça na Sexta-Feira, caso ele se
              matricule em um de nossos planos anuais, você ganha um mês de
              treinos.{" "}
            </p>
            <p>
              Confira o regulamento na recepção. (Promoção somente de
              Sexta-Feira)
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
